.site-page-footer{
    padding-left: 15vw !important;
    position: absolute;
}
.site-page-footer-inner{
    width: 40vw;
}
.ant-col>h1{
    color: white;
    font-size: calc(1.25vw + 0.5em);
    font-weight: bold;
}

.list-footer{
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: calc(0.75vw + 0.3em);
    display: flex;
    flex-direction: column;
}
.list-footer>li:last-child{
    font-size: calc(0.75vw + 0.5em);
}
.media-sosial img{
    margin-right: 3px;
}
.list-footer-header {
    font-size: 18px !important;
}

/* Tablet */
@media screen and (min-width:598px) and (max-width:992px){
    .site-page-footer-inner{
        width: 60vw;
    }
    .ant-layout-footer {
        position: absolute;
    }
    .list-footer-header {
        font-size: 14px !important;
    }
}

/* Mobile */
@media screen and (max-width:598px){
    .site-page-footer-inner{
        width: 75vw;
    }
    .ant-col>h1{
        font-size: calc(1.25vw + 0.5em);
    }
    .list-footer{
        font-size: calc(1vw + 0.3em);
    }
    .list-footer>li:last-child{
        font-size: calc(1vw + 0.5em);
    }
    .media-sosial>img{
        width: 15px;
    }
    .ant-layout-footer {
        position: absolute;
    }
    .list-footer-header {
        font-size: 12px !important;
    }
}