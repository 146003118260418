.site-page-produk {
  height: 350px;
  margin-top: 30px;
  margin-bottom: 125px;
}
.slick-slider,
.slick-list,
.ant-spin-nested-loading,
.ant-spin-container {
  height: inherit;
}
.ant-list-something-after-last-item {
  width: 100%;
}
.slider > .slick-list > .slick-track {
  transform: translate3d(0px, 0px, 0px) !important;
}
.slick-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ant-spin-container,
.ant-spin-nested-loading {
  width: inherit;
}
/* Card Produk */
.card-produk {
  margin: auto !important;
  -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%),
    0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%);
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: border-color 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
  width: 200px;
}
.card-produk:hover {
  cursor: pointer;
  box-shadow: 1px 1px 8px grey;
}
.ant-card-cover {
  padding: 5px 5px 0px 5px;
  text-align: center;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Next/Prev */
.icon-prev-produk,
.icon-next-produk {
  width: 35px;
  z-index: 99;
  position: absolute;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  height: 35px;
}
.icon-next-produk.slick-disabled,
.icon-prev-produk.slick-disabled {
  opacity: 0;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

/* Custom Arrow Pagination */
.anticon.anticon-right::before,
.anticon.anticon-left::before {
  content: "";
  width: 0;
  display: block;
  height: 0;
  position: absolute;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-radius: 3px;
}
.anticon.anticon-left::before{
  border-top: 7px solid transparent;
  border-right: 11px solid white;
  border-bottom: 7px solid transparent;
  right: 8px;
}
.anticon.anticon-right::before{
  border-top: 7px solid transparent;
  border-left: 11px solid white;
  border-bottom: 7px solid transparent;
  left: 8px;
}
.ant-pagination-next.ant-pagination-disabled,
.ant-pagination-prev.ant-pagination-disabled {
  opacity: 0.3;
}
.ant-pagination-prev > .ant-pagination-item-link,
.ant-pagination-next > .ant-pagination-item-link {
  background-color: #ee6a69 !important;
  color: white !important;
  border-radius: 50% !important;
  width: 25px !important;
  position: relative;
}


/* Produk All */
.site-page-produk-all {
  padding-top: 50px;
}
.card-produk.all,
.card-produk {
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-produk > div > img,
.card-produk.all > div > img {
  width: 100%;
  height: 100%;
  padding: 5px;
  object-fit: contain;
}
.card-produk > .ant-card-body,
.card-produk.all > .ant-card-body {
  padding: 15px !important;
  height: 40%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.card-produk.all > div > p,
.card-produk.all > div > h4,
.ant-card-body p,
.card-produk span {
  font-size: 12px;
}
.checkbox-list {
  padding: 10px 15px;
}
.checkbox-list > span,
.card-produk.all > div > h4 {
  font-weight: bold;
}
.ant-pagination-simple {
  text-align: center;
}
.landscape{
  width: 20vw;
  height: 30vw;
}

/* Filter */
.card-filter .ant-card-body {
  padding: 10px !important;
}
.ant-checkbox-wrapper > span {
  font-size: 12px;
}
.filter-mobile {
  height: 500px;
  transition: 0.5s ease-in-out;
  background: white;
  overflow: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ant-btn.apply-btn {
  margin-top: 2rem;
  width: 100%;
  display: none;
}


/* Detail Produk */
.site-page-detail-produk {
  margin-top: 10px;
}
.slider-detail-produk {
  width: 500px;
  height: 500px;
  margin: auto;
}
.detail.card-produk {
  width: 450px;
  height: 450px;
}
.detail > .ant-card-body {
  display: none;
}
.detail > .ant-card-cover {
  padding: 15px;
  height: inherit;
}
.btn-detail-produk {
  width: 500px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
}
.keterangan-detail-produk {
  margin-top: 30px;
}
.keterangan-detail-produk > ul {
  padding: 0;
}
.keterangan-detail-produk .detail-produk ul li {
  list-style: "-";
}
.detail > .ant-card-cover {
  padding: 0 !important;
}


/* PC Lebar */
@media screen and (min-width: 1500px) {
  .card-produk.all {
    width: 200px;
    height: 300px;
  }
  .card-produk{
    width: 225px;
    height: 325px;
  }
}


/* Tablet */
@media screen and (min-width: 598px) and (max-width: 992px) {
  /* Default Card Responsive */
  .ant-card-body {
    padding: 15px !important;
  }
  .ant-card-cover {
    padding: 5px 5px 0px 5px !important;
  }
  .LinesEllipsis {
    font-size: calc(0.75vw + 0.5em);
  }
  .card-produk.all > div > p,
  .card-produk span {
    font-size: calc(0.75vw + 0.45em);
  }
  .ant-card-body > span,
  .ant-card-body > p {
    font-size: calc(0.75vw + 0.35em);
  }


  /* All Produk & Produk Baru */
  .site-page-produk {
    height: 35vw;
    margin-bottom: 75px;
    margin-top: 0px;
  }
  .card-produk,
  .card-produk.all {
    width: 22.5vw;
    height: 30vw;
  }
  .card-produk-baru .slick-track{
    left: -15vw;
    position: absolute;
    top: 20px;
  }


  /* Detail Produk */
  .card-produk.detail {
    width: 45vw;
    height: 40vw;
  }
  .slider-detail-produk {
    width: 50vw;
    height: 55vw;
  }
  /* .keterangan-detail-produk {
    font-size: 12px;
  } */
}

/* Mobile */
@media screen and (max-width: 598px) {
  .site-page-produk-all {
    padding-top: 25px;
  }
  .site-page-produk {
    height: 40vw;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .ant-list-something-after-last-item {
    width: 100%;
  }

  /* Produk All & Produk Baru */
  .card-produk.all, .card-produk {
    width: 35vw;
    height: 45vw;
  }
  .card-produk.home{
    width: 30vw;
    height: 35vw;
  }
  .ant-card-cover {
    padding: 5px 5px 0px 5px !important;
  }
  .card-produk > .ant-card-body,
  .card-produk.all > .ant-card-body {
    padding: 10px 10px 0px 10px !important;
  }
  .LinesEllipsis {
    font-size: calc(0.75vw + 0.5em);
  }
  .card-produk span {
    font-size: calc(0.75vw + 0.65em);
  }
  .ant-card-body > span,
  .ant-card-body > p,
  .card-produk.all > div > p {
    font-size: calc(0.75vw + 0.5em);
  }
  .icon-prev-produk,
  .icon-next-produk {
    width: 20px;
  }
  .card-produk-baru .slick-track{
    left: -30vw;
    position: absolute;
    top: 20px;
  }


  .btn-filter {
    font-size: 8px !important;
    height: 20px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .ant-pagination-prev::before {
    transform: translateY(0px);
  }
  .ant-pagination-next::before {
    transform: translateY(0px) translatex(10px);
  }
  .ant-pagination-prev::before,
  .ant-pagination-next::before {
    width: 25px;
    background-size: 25px;
  }

  /* Filter */
  .ant-btn.apply-btn {
    display: initial;
  }

  /* Detail Produk */
  .slider-detail-produk {
    width: 60vw;
    height: 60vw;
  }
  .card-produk.detail {
    width: 45vw;
    height: 40vw;
  }
  /* .keterangan-detail-produk {
    font-size: 10px;
  } */
  .btn-detail-produk {
    width: 250px;
    margin-top: 50px;
  }
  .btn-detail-produk > a > button {
    font-size: 12px;
    height: 28px;
  }
}

@media screen and (max-width:500px) {
  .card-produk-baru .slick-track{
    left: -30vw;
  }
  .card-produk.home{
    width: 32.5vw;
    height: 45vw;
  }
  .site-page-produk {
    height: 55vw;
    margin-bottom: 75px;
  }
}