.site-page-carousel,
.site-page-carousel-inner{
  height: 43.5vw;
}
.site-page-carousel {
  position: relative;
}
.site-page-carousel-inner {
  width: 100%;
}
.img-banner {
  width: 100%;
  z-index: -1;
  position: relative;
  cursor: pointer;
}
.slick-dots {
  bottom: 45px !important;
}

/* Icon Carousel */
.icon-prev,
.icon-next {
  width: 35px;
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}
.icon-prev {
  left: 100px;
}
.icon-next {
  right: 100px;
}
.icon-prev:hover,
.icon-next:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

/* Dost Carousel */
.slick-custom-dots {
  width: 10px;
  color: grey;
  border-radius: 50%;
  height: 10px;
}

/* PC Lebar */
@media screen and (min-width: 1500px) {
  .site-page-carousel,
  .site-page-carousel-inner{
    height: 700px;
  }
}

/* Tablet */
@media screen and (min-width: 599px) and (max-width: 992px) {
  .icon-prev {
    left: 40px;
  }
  .icon-next {
    right: 40px;
  }
}
/* Mobile */
@media screen and (max-width: 598px) {
  .img-banner {
    width: 100%;
  }
  .icon-next,
  .icon-prev {
    width: 20px;
  }
  .icon-prev {
    left: 35px;
  }
  .icon-next {
    right: 35px;
  }
  .slick-custom-dots {
    width: 5px;
    height: 5px;
  }
  .slick-dots {
    bottom: 5px !important;
  }
}
