.container{
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 18vw;
}
.beranda{
    width: 75vw;
    padding-top: 75px;
    padding-bottom: 75px;
    margin: auto;
}
.home .beranda{
    padding-bottom: 0;
}
.sub-title a {
    padding-left: 50px;
}
.sub-title{
    font-size: 24px;
}
.col-all-kategori{
    margin-top: 20px;
    font-size: 16px;
}

/* Breadcrumb */
.breadcrumb{
    background: #EFEFEF;
    border-radius: 10px;
    height: 40px;
    padding: 10px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #F16663;
}
.breadcrumb .ant-breadcrumb {
    font-size: 13px;
}

/* Tablet */
@media screen and (min-width:599px) and (max-width:992px){
    .beranda{
        padding-top: 50px;
    }
    .container{
        padding-bottom: 27vw;
    }
    .sub-title{
        font-size: 18px;
    }
}
/* Mobile */
@media screen and (max-width:598px){
    .beranda{
        padding-top: 30px;
    }
    .container{
        padding-bottom: 38vw;
    }
    .sub-title a {
        padding-left: 20px;
    }
    .sub-title{
        font-size: 10px;
    }
    .col-all-kategori>a>p{
        font-size: 8px;
    }
    .col-all-kategori{
        margin-top: 10px;
    }
    .ant-breadcrumb>span{
        font-size: 10px;
    }
    .ant-breadcrumb {
        line-height: normal;
    }
    .breadcrumb{
        padding: 5px 10px;
        height: auto;
        align-items: unset;
    }
}