.site-page-tentang-kami-img{
    width: 100%;
    height: 30vw;
}
.beranda.tentang-kami{
    padding-top: 100px;
    padding-bottom: 100px;
}
.beranda.tentang-kami.top{
    padding-top: 25px;
    padding-bottom: 25px;
}
@media screen and (max-width: 769px){
    .site-page-tentang-kami{
        line-height: calc(1.5vw + 0.75em);
        font-size: calc(1vw + 0.275em);
    }
    .beranda.tentang-kami{
        padding-bottom: 75px;
        padding-top: 25px;
    }
}