.site-page-pendaftaran {
  padding-top: 30px;
}
.ant-form-item-label > label {
  font-weight: bold;
  pointer-events: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  pointer-events: initial;
}
.address-form > .ant-form-item > .ant-form-item-label {
  padding-left: 30px;
}

/* Input */
.ant-input:not(.ant-input-affix-wrapper > .ant-input),
.ant-input-number,
input:not(.ant-input-affix-wrapper > .ant-input),
textarea {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input:not(.rtrw, .ant-input-affix-wrapper > .ant-input) {
  padding-left: 25px !important;
}
.rtrw input {
  text-align: center;
}

.ant-form-item-control-input-content > span {
  display: flex;
  align-items: center;
}
.ant-upload-list-item-info,
.ant-upload-list-item-list-type-text {
  margin: 0 !important;
}

.ant-upload-list {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.upload-form {
  display: flex;
  align-items: center;
}

/* Phone Input */
.ant-form-item-control,
.ant-form-item-control-input,
.ant-form-item-control-input-content,
.PhoneInputCountry:focus-visible,
.PhoneInput:focus-visible,
.PhoneInput input{
  outline: none;
}
.PhoneInput input
 {
  border: none;
  border-radius: 10px;
}
.error{
  border-radius: 10px;
}


/* Button */
.btn-form {
  width: 350px;
  margin-top: 30px;
}
.btn-daftar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Modal */
.ant-modal-header,
.ant-modal-body > .ant-row,
.ant-modal-footer {
  text-align: center !important;
  justify-content: center;
}
.box-syarat-ketentuan {
  text-align: justify;
  height: 250px;
  overflow: auto;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.ant-checkbox-inner {
  border: 1px solid #623310 !important;
}
.ant-checkbox-wrapper {
  margin-top: 20px !important;
}
.btn-modal {
  margin-top: 20px;
}

/* Phone Number */
.PhoneInputCountry {
  display: none;
}
.PhoneInputInput {
  width: 100%;
  padding: 4px 25px;
  border: none;
}

@media screen and (max-width: 769px) {
  .address-form > .ant-form-item > .ant-form-item-label {
    padding: 0;
  }
  .btn-form {
    width: 50vw;
  }
  .btn-daftar .ant-col-md-offset-6 {
    margin-left: auto;
    margin-right: auto;
  }
  .btn-daftar {
    text-align: center;
  }
  #tooltips {
    font-size: calc(0.5vw + 8px);
  }
}

/* disable auto-zoom on iphone input field focus */
/* from : https://gist.github.com/vedranjaic/5653472 */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 320px) {
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

/* @media screen and (max-width : 320px) {
  input{
    font-size: 16px !important;
  }
} */
