.site-page-header{
    box-shadow: 0px 3px 10px grey;
    background-color: white !important;
    z-index: 999;
}


/* Image Header */
.site-page-col-icon img{
    width: 300px;
    margin-left: 50px;
}


/* Search Input*/
.site-page-col-search{
    width: 500px;
    margin-right: 50px;
    transition: 0.5s ease-in-out;
}
.ant-input-group-wrapper{
    width: 100%;
}
.ant-input-group-addon{
    left: 5px !important;
}
.ant-input-search-button{
    border: none !important;
    box-shadow: 1px 1px 3px gray !important;
}
.site-page-header-search>span>.ant-input-affix-wrapper{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    box-shadow: 1px 1px 5px grey;
}
.site-page-header-search > span > .ant-input-group-addon{
    background: transparent !important;
}


@media screen and (min-width: 599px) and (max-width: 1080px){
    .site-page-col-search{
        width: 35vw;
    }
    .site-page-col-icon img{
        width: 200px;
    }
}

@media screen and (min-width: 320px) and (max-width: 598px){
    .site-page-col-search{
        margin-right: 0px;
        width: 45vw;
    }
    .site-page-col-search:hover{
        width: 60vw !important;
        transition: 0.5s ease-in-out;
    }
    .site-page-col-icon img{
        width: 15vw;
        margin-left: 0px;
    }
    .site-page-header-search>span>.ant-input-affix-wrapper{
        border-radius: 40px !important;
        box-shadow: 1px 1px 5px grey;
    }
}