@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');
body,html{
  height: 100%;
  position: relative;
}
#root{
  height: inherit;
}
body {
  overflow-x: hidden;
  font-family: "Rubik", sans-serif !important;
}

ol {
  padding-left: 30px;
  list-style-type: decimal-leading-zero;
}
ul{
  padding-left: 20px;
}
ul>li{
  padding-left: 0px;
  list-style: none;
}
textarea{
  max-height: 300px;
  min-height: 150px;
}

::placeholder {
  opacity: 1;
}

button{
  border-radius: 20px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
}

@media screen and (max-width:598px){
  ol{
    padding-left: 20px;
  }
}
