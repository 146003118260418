.site-page-ketentuan {
  padding-top: 30px;
}
.site-page-ketentuan > h3 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}
.site-page-ketentuan > ol > li {
  margin-bottom: 10px;
}

@media screen and (max-width:598px){
  .site-page-ketentuan {
    padding-top: 15px;
    font-size: 10px;
  }
  .ant-table-content{
    font-size: 10px;
  }
}