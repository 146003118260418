.site-page-kategori-inner{
    padding: 50px;
}
.kategori-row{
    padding-top: 40px;
}

/* Card Kategori */
img.Toy-Indoor{
    transform: translateY(-42px);
}
img.Toy-Box{
    transform: translateY(-7px);
}
img.Toy-Age{
    transform: translateY(7px);
}
img.Toy-Available{
    transform: translateY(-6px);
}
img.Toy-Sun{
    transform: translateY(-1px);
}
.kategori-card{
    cursor: pointer;
}

/* Tablet */
@media screen and (min-width:599px) and (max-width:992px){
    .kategori-card>img{
        width: 20vw;
    }
    img.Toy-Indoor{
        transform: translateY(-4vw) translateX(8vw);
    }
    .site-page-kategori-inner{
        padding: 30px;
    }
}

/* Mobile */
@media screen and (max-width:599px){
    .kategori-card>img{
        width: 20vw;
    }
    .kategori-row{
        padding-top: 20px;
    }
    .site-page-kategori-inner{
        padding: 10px;
        padding-top: 20px;
    }
    img.Toy-Indoor{
        transform: translateY(-3.75vw) translateX(9vw);
    }
    img.Toy-Box{
        transform: translateY(-1vw);
    }
    img.Toy-Age{
        transform: translateY(0.5vw);
    }
    img.Toy-Available{
        transform: translateY(-0.5vw);
    }
    img.Toy-Sun{
        transform: unset;
    }
}