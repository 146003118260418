::placeholder {
  color: #eeb7b7 !important;
}
.ant-input-search-button {
  color: rgba(244, 102, 104, 255) !important;
}
.site-page-tentang-kami,
.site-page-tentang-kami > h3,
.ant-form-item-label > label,
.ant-table,
.site-page-ketentuan,
.card-produk p,
.sub-title,
.site-page-kategori-inner>.ant-row>h1,
.checkbox-list>span,
.ant-checkbox-wrapper>span,
.checkbox-list>li,
.keterangan-detail-produk>h4
{
  color: #623310;
}
.ant-modal-header > .ant-modal-title,
.ant-modal-body > .ant-row,
.ant-modal-footer,
.ant-form-item-label > label {
  color: #623310 !important;
}
.site-page-ketentuan > h3,
.card-produk h4,
.box-syarat-ketentuan > h3,
.keterangan-detail-produk>h2 {
  color: #ce3336;
}

/* Carousel */
.slick-custom-dots {
  border: 2px solid #dfbcbc;
  background-color: #dfbcbc;
}
.slick-active > .slick-custom-dots {
  background-color: #ee6a69;
}
.ant-breadcrumb > span:last-child a,
#current {
  color: #f16663 !important;
}
.site-page-footer {
  background-color: #f16663;
}
.card-produk span,
.keterangan-detail-produk>p {
  color: #f7931d;
}
.card-produk p span {
  color: #623310;
}
.ant-table,
.ant-table-thead > tr > th {
  background: white !important;
}
.list-footer > a {
  color: white !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border: 1px solid #cb3336;
  border-right: 1px solid #cb3336 !important;
  border-bottom: 1px solid #cb3336 !important;
  border-radius: 0px !important;
}
input, textarea {
  color: #cb3336 !important;
}
input:active:not(.ant-input-affix-wrapper > .ant-input),
input:hover:not(.ant-input-affix-wrapper > .ant-input),
input:visited:not(.ant-input-affix-wrapper > .ant-input),
input:link:not(.ant-input-affix-wrapper > .ant-input),
input:focus:not(.ant-input-affix-wrapper > .ant-input),
textarea:hover,
textarea:visited,
textarea:link,
textarea:focus {
  border: 1px solid black !important;
}

/* Button */
.ant-btn-primary:focus{
  background-color: #ee6a69 !important;
}
.ant-btn-primary:hover{
  background-color: #fa9996 !important;
}


/* Filter */
.ant-btn.apply-btn {
  background-color: #f16663;
  color: white;
}


/* Error Border */
.error{
  border: 1px solid #ff4d4f;
}
.error:focus{
  border: 1px solid black;
}
.error:hover{
  border: 1px solid black;
}

/* Mobile */
@media screen and (max-width: 598px) {
  .ant-pagination-simple .ant-pagination-simple-pager input {
    border-color: #592c0d !important;
  }
  .ant-btn-primary:hover{
    background-color: #fa9996 !important;
  }
  .ant-btn-primary:focus{
    background-color: #ee6a69 !important;
  }
}

/* Tablet */
@media screen and (min-width: 599px) and (max-width: 992px) {
  .ant-pagination-simple .ant-pagination-simple-pager input {
    border-color: #592c0d !important;
  }
  .ant-btn-primary:hover{
    background-color: #fa9996 !important;
  }
  .ant-btn-primary:focus{
    background-color: #ee6a69 !important;
  }
}